.columnTitle {
    font-weight: bold;
}

.element {
    padding: 6px;
    border-radius: 10px;
    border: 1px solid #515151;
    display: flex;
    flex-wrap: wrap;
    background: #1f1a25;
    margin-bottom: 14px;
}
.datapoint{
    padding: 16px;
}
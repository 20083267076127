.wrapper {
    padding-bottom: 120px;
}

.filters {
    display: flex;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.filter {
    max-width: 100%;
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
}


.vignetteWrapper {
    width: 340px;
    max-width: 100%;
    margin-right: 12px;
    margin-bottom: 12px;
    background: #2c2533;
    border: 1px solid #3a3043;
    border-radius: 10px;
    display: flex;

    .vignetteInner {
        position: relative;
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
    }

    .domainDetailsWrapper {
        flex-grow: 1;
        display: flex;
    }

    .vignetteCTAs {
        display: flex;
        margin-top: 22px;

        .vignetteCTA {
            flex-basis: 50%;

            .vignetteCTAInner {
                margin: 4px;

            }
        }
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.categorySelectFilter {
    :global {
        .ant-select-selector {
            background: #2c2533 !important;
            border-color: #3a3043 !important;
            max-width: 100% !important;
        }



        .ant-select-arrow {
            color: grey !important;
        }

        .ant-select-selection-item-content {
            color: white
        }

        .ant-select-clear {
            color: grey !important;
        }
    }
}

.searchInput {
    background: #2c2533 !important;
    border-color: #3a3043 !important;
    max-width: 100% !important;

    input {
        color: white !important;
    }
}


.filterRight {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
    color: grey;
}

.onlyGoodMatchButton {
    border: 1px solid #383838;
    padding: 8px 12px;
    border-radius: 10px;
    color: #1f1a25;
    font-weight: bolder;
    background: white;
}

.onlyAvailableButton {
    border: 1px solid #383838;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    font-weight: bolder;
}

.onlyYCButton {
    border: 1px solid #383838;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    font-weight: bolder;
    display: flex;
    align-items: center;
}

.ycLogo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 2px;
    margin-right: 6px;
}

@media (max-width: 730px) {
    .list {
        justify-content: center;
    }

    .vignetteWrapper {
        margin: 12px;
    }
}
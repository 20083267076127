.value {}

.value80 {
    color: #000;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(309deg, #c22e2e 10.33%, #ff0000 34.36%, #ff2c2c 60.15%, #ff870e 84.04%, #df2424 92.04%);
    -webkit-background-clip: text;
    background-clip: text;
    display: inline;
}

.value60 {
    color: #bea5ff;
}

.value40 {
    color: #51db51;
}

.value20 {

    color: #4afced;
}

.value10 {

    color: #8aabff;
}

.value5 {
    color: #ffeb6b;
}

.value0 {

    color: #ffeb6b;
}
.wrapper {
    text-align: center;
    width: 100%;
    background: red;
    color: black;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 10000;
}
.inner {
    padding: 8px 12px;
}
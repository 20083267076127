.vignetteMetrics {
    display: flex;
}

.vignetteDesc {
    font-size: 20px;
    color: white;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ctaWrapper {
    display: flex;
    width: 100%;
    margin-top: 24px;

    .ctaRight {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
}

.check {
    color: #16b116;
    font-size: 144px;
    background: radial-gradient(white 55%, #16b116 10%);
    border-radius: 50%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.smallIcon {
    background: radial-gradient(white 55%, #16b116 10%);
    border-radius: 50%;
    font-size: 20px;
}

.successInner {
    text-align: center;
}

.nextSteps {
    text-align: left;
    width: 400px;
    margin: auto;
    margin-top: 34px;

}

.nextStep {
    display: flex;
    margin-bottom: 8px
}

.nextStepIcon {
    margin-right: 12px;
}
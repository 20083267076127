.vignetteMetrics {
    margin-top: 14px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.vignetteCateg {
    margin-bottom: 12px;
}

.vignetteDesc {
    font-size: 18px;
    color: white;
    flex-grow: 1;
}

.name {
    font-weight: bold;
    color: white;
    display: flex;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .responsive,
    .goodMatch {
        margin-bottom: 6px;
    }
}

.goodMatchWrapper {
    margin-top: 0px;
}



.goodMatch {
    // width: calc(100% - 8px);
    text-align: center;
    margin-left: 4px;
    margin-right: 4px;
    padding: 1px 10px;
    border-radius: 6px;
    font-size: 11px;
    color: #1f1a25;
    font-weight: bolder;
    background: white;
    // background-image: linear-gradient(220deg, #0da880 10.33%, #4afced 34.36%, #c191e5 60.15%, #ac88d7 84.04%, #a981d9 92.04%, #704f97);
}

.fastResponderWrapper {
    margin-top: 0px;
}
.ycLogo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 8px;
}
.logo {
    display: flex;
    align-items: center;
}

.iconWrapper {
    margin-right: 8px;
}

.icon {
    width: 28px;
    height: 28px;
    border-radius: 4px;
}

.text {
    font-weight: bold;
    color: #aba2b4;
}
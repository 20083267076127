.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: #1f1a25f0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 900;

    .inner {
        margin-left: 12px;
        margin-right: 12px;
    }

    .content {
        width: 100%;
        max-width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}


.vignetteWrapper {
    width: 670px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #302b35;
    border-radius: 10px;
    margin-bottom: 24px;
    min-height: 300px;

    .vignetteInner {
        padding: 58px 64px;
    }
}

.logoWrapper {
    display: flex;
    margin-bottom: 44px;
    align-items: center;

    .logoInner {
        flex-grow: 1;
    }

    .logout {
        color: grey;

    }
}

.title {
    text-align: center;
}

.icon {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    img {
        width: 150px;
        height: 150px;
    }
}

.cta {
    display: flex;
    justify-content: center;
    margin-top: 64px;

    .back {
        flex-grow: 1;
    }
}

.arguments {
    margin-top: 14px;
    margin-bottom: 44px;
}

.subtitle {
    text-align: center;
}

.arguments {
    width: 500px;
    max-width: 100%;
    margin: auto;
    margin-top: 34px;
    margin-bottom: 64px;

    ul {
        list-style: none;

        li {
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;
        }

        .argumentIcon {
            margin-right: 8px;
            font-size: 22px;
            color: #14d9a6;
        }
    }
}

.formWrapper {
    margin: auto;
    margin-top: 24px;

}

.hint {
    color: grey;
    margin-top: 4px;
    margin-left: 4px;
    font-style: italic;
}

.settingsWrapper {
    margin-top: 64px;

    h1 {
        text-align: center;
    }
}

.aiPulled {
    color: #9a4df6;
    margin-top: 4px;
    margin-bottom: 24px;
}

.formItemSettings {
    margin-bottom: 0;
    margin-top: 18px;
}

.settingsFormWrapper {
    margin-top: 44px;
}

.verifyIcon {
    font-size: 114px;
    color: #14d9a6;
    text-align: center;
}

.loader {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 44px;
}

.linkedinButton {
    background: #007ab6;
    font-weight: bold;
    width: 320px;
    max-width: 100%;

    &:hover {
        background: #036392 !important;
    }

}

.linkedinButtonWrapper {
    margin-top: 44px;
    margin-bottom: 64px;
}

.linkedinButtonInner {
    display: flex;
    justify-content: center;
}

.nextButton {
    width: 300px;
    max-width: 100%ƒ;
}

.nextStepElement {
    background: #1f1a25;
    padding: 12px 24px;
    margin-bottom: 18px;
    display: flex;
}

.nextStepAction {
    margin-top: 12px;
}

.nextStepIcon {
    font-size: 44px;
    padding-top: 18px;
    padding-right: 18px;
}

.whatNextTitle {
    text-align: center;
    margin-top: 24px;
}

.instructionsBacklink {
    li {
        margin-bottom: 8px;
    }
}

.useAnother {
    display: flex;
    justify-content: flex-end;
}

.inviteWrapper {
    padding: 24px;
    background: #262229;
    border-radius: 10px;
    text-align: center;
    color: white;

}

.inviteTitle {
    margin-top: 0;
    text-align: center;
}

.codeFormWrapper {
    margin-top: 24px;
}

.criteriaWrapper {
    text-align: center;
}

.choicesMainTitle {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 6px;
}

.choice {
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid #464646;
    border-radius: 10px;
    display: flex;

    .choiceIcon {
        margin-right: 16px;
        font-size: 28px;
    }

    .choiceTitle {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 6px;
    }

    .choiceDescription {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .choiceButton {}
}

@media screen and (max-width: 520px) {
    .vignetteWrapper {
        .vignetteInner {
            padding: 34px 20px;
        }
    }

    .hideMobile {
        display: none;
    }

}
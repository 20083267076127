body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #1f1a25;
  color: #c3c3c3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tag {
  padding: 4px 10px;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.ant-modal,
.ant-modal .ant-modal-title {
  color: #393939;
}

.ant-modal .ant-modal-title {
  font-size: 20px;
}

.ant-btn {
  box-shadow: none;
}

.ant-radio-button-wrapper,
.ant-alert,
.ant-input,
.ant-select .ant-select-arrow,
.ant-select .ant-select-selector,
.ant-select-dropdown .ant-select-item,
.ant-select-dropdown .ant-select-item-option-selected,
.ant-btn-color-default,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-input-suffix,
.ant-alert-with-description .ant-alert-message,
.ant-alert-with-description .ant-alert-description,
.ant-select-tree-title,
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-switcher {
  color: #393939 !important;
}

.ant-typography {
  color: #cecece !important;
}

.ant-radio-button-wrapper:hover {
  color: #08a87f !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white !important;
}

.ant-input-disabled,
.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled:where(.css-dev-only-do-not-override-1xaq9ag).ant-select-multiple.ant-select-lg .ant-select-selection-overflow .ant-select-selection-item {
  color: #c0c0c0 !important;
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover>td,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder,
.ant-table-wrapper .ant-table-cell-fix-right {
  background: #322c38;
}

.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after {
  box-shadow: inset -10px 0 8px -8px rgb(5 5 5 / 27%);
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: none;
}

.ant-table-wrapper:hover .ant-table .ant-table-cell.ant-table-cell-row-hover {
  background: #4a4350;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: 1px solid #4f4f4f;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #4f4f4f;
}

.ant-divider {
  border-color: grey;
}

.ant-modal .ant-modal-content,
.ant-modal .ant-modal-header {
  background-color: #271e2f;
}

.ant-modal-confirm .ant-modal-confirm-content,
.ant-modal,
.ant-modal .ant-modal-title {
  color: #ebebeb;
}

.ant-alert-with-description .ant-alert-message {
  font-weight: bold;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-top>.ant-tabs-nav::before {
  border-color: #383838;
}

.ant-select-dropdown {
  z-index: 11000;
}


.ant-tree-select .ant-select-selection-item {
  background: #08a87f47 !important;
}

.ant-select-selection-item-remove {
  color: #8e8e8e !important;
}

.ant-btn-variant-outlined:disabled {
  color: #80808045 !important;
}

.ant-tooltip .ant-tooltip-inner {
  background: black
}

.ant-btn-variant-solid:disabled,
.ant-btn-variant-outlined:disabled {
  border: none;
}

.ant-select-clear {
  color: #a1a1a1
}

.ant-divider {
  border-color: #404040;
}

.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  color: #271e2f;
}

.ant-alert .ant-alert-close-icon .anticon-close {
  color: #4e4e4e73 !important;
}

.ant-modal-confirm-title {
  color: #ebebeb !important;
}

.ant-modal-close-x {
  color: #5b5b5b !important;
}

a {
  color: #08a87f !important;
  cursor: pointer !important;

  &:hover {
    color: #08a87f !important;
    text-decoration: underline !important;
  }
}
.metricsWrapper {
    flex-basis: calc(50% - 12px);
    margin: 4px;
    // background: grey;
    border: 1px solid #c7c6c821;
    border-radius: 10px;

    .metricsInner {
        padding: 12px 14px;

        .metricsValue {
            font-size: 22px;
            font-weight: bold;
        }

        .metricsLabel {}

    }

    &.large {
        flex-basis: 100%;

        .metricsInner {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.responsive {
    // width: calc(100% - 8px);
    text-align: center;
    margin-left: 4px;
    margin-right: 4px;
    padding: 1px 10px;
    border-radius: 6px;
    font-size: 11px;
    color: #1f1a25;
    font-weight: bolder;
    background: #ffe454;
    // background-image: linear-gradient(220deg, #0da880 10.33%, #4afced 34.36%, #c191e5 60.15%, #ac88d7 84.04%, #a981d9 92.04%, #704f97);
}
.wrapper {
    margin-bottom: 12px
}

.content {
    display: flex;
    flex-wrap: wrap;
}

.link {
    

    code {
        background: #e3e3e3 !important;
        color: black;
    }
}

.contentLeft {
    // flex-grow: 1;
    margin-right: 14px;
}
.modal .ant-modal-content {}

.switchPause {
    margin-left: 10px;
    margin-right: 10px;
}

.pauseWrapper {
    margin-top: 24px;
    margin-bottom: 34px;
    margin-left: 4px;
}

.hint {
    color: grey;
    margin-top: 6px;
}
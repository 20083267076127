.instructionsWrapper {
    margin-top: 12px;
    border: 1px solid rgba(199, 198, 200, 0.1294117647);
    border-radius: 10px;
    padding: 12px;
}

.instructionsTitle {
    margin-bottom: 8px;
}

.cta {
    display: flex;
    gap: 12px;

    button {
        flex: 1;
    }
}

@media screen and (max-width: 500px) {
    .cta {
        flex-wrap: wrap;
    }
}
.wrapper {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vignette {
    width: 370px;
    max-width: 100%;
    padding: 48px 64px;
    margin: auto;
    background: #302b35;
    border-radius: 10px;
    margin-bottom: 44px;
}

.icon {
    font-size: 84px;
    margin-bottom: 12px;
}
.cta{
    text-align: center;
}
.pendingWrapper {
    padding: 18px;
    padding-top: 1px;
    background: #faf3d1;
    padding-bottom: 24px;
    border-radius: 10px;

    h1 {
        color: #1f1a25;
    }
}

.otherRequestsWrapper {
    margin-top: 24px;
}

.footer {
    display: flex;
    margin-top: 24px;
    display: flex;
    gap: 12px;

    .footerElement {
        flex: 1;
    }
}

.wrapper {
    padding-bottom: 120px;
}

.requestedBy {
    font-size: 12px;
    font-style: italic;
    margin-top: 4px;
    color: grey;
}

.hintRequest {
    color: grey;
    margin-top: 12px;
    text-align: center;
}
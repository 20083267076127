.title {
    text-align: center;
    color: white;
    margin-bottom: 4px;
}

.description,
.stepDescription {
    font-weight: 400;
    text-align: center;
    margin-top: 4px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 24px;
}

.step {
    text-align: center;
}

.stepTitle {
    text-align: center;
    margin-top: 4px;
}

.stepWrapper {
    margin-top: 34px;
    padding: 24px;
    border: 1px solid #c7c6c821;
    border-radius: 10px;
}

.image {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    height: auto;
    margin-top: 12px;
}
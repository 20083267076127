.wrapper {
    width: 1100px;
    max-width: 100%;
    margin: auto;
    margin-top: 64px;

    .inner {
        margin: 12px;
    }

}

.domainsListWrapper {
    display: flex;
    flex-wrap: wrap;
}

.domainTag {
    border-radius: 48px;
    margin-right: 12px;
    margin-bottom: 12px;
    background: #9aadbc !important;
    color: #198e6b !important;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        background: white !important;
    }

    &.selectedTag {
        background: white !important;
    }


    &.domainNotLive {
        color: #051200 !important;

    }

    &.domainRejected {
        color: #a95858 !important;
        background: #9f09094f !important;


        &:hover {
            cursor: pointer;
            background: white !important;
        }
    }
}

.domainTagAdd {
    border: 1px solid grey !important;
    color: grey !important;
    background: transparent !important;
}



.loaderWrapper {
    width: 100%;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    justify-content: center;
}

.inReviewWrapper {
    padding: 12px 18px;
    background: #c8c2ff;
    color: #303030;
    border-radius: 10px;

    .inReviewInner {
        display: flex;
        align-items: center;

        .inReviewIcon {
            font-size: 34px;
            margin-right: 18px;
        }
    }
}

.rejectedWrapper {
    padding: 12px 18px;
    background: #a20e26;
    color: white;
    border-radius: 10px;
    margin-top: 24px;

    .rejectedInner {
        display: flex;
        align-items: flex-start;

        .rejectedIcon {
            font-size: 34px;
            margin-right: 18px;
            margin-top: 20px;
        }

        .rejectedCta {
            margin-left: 20px;
            margin-top: 20px;
        }
    }
}

.detailsWrapper {}

.vignetteWrapper {
    padding: 24px;
    margin-right: 12px;
    margin-bottom: 12px;
    width: 340px;
    border-radius: 10px;
    color: #c7c6c8;
    // border: 1px solid #322c38;
    // background: #2b2433;
    background: linear-gradient(30deg, #2a2334, #212121);
    border: 1px solid #312a3c;
    position: relative;

    &.vignetteFeatured {
        // background: #188e6b;
        background: linear-gradient(30deg, #2a2334, #212121);
        // color: white;
        // border: 1px solid #10674d;
        border: 1px solid #312a3c;
    }

    .vignetteFigure {
        font-weight: bold;
        font-size: 33px;
        margin-bottom: 6px;

    }

    .vignetteName {}


    .extra {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.domainRatingExtra {
    color: grey;
    font-style: italic;
    margin-right: 8px;
    margin-top: 8px;
    font-size: 11px;
}

.status {
    font-weight: bold;
    text-transform: capitalize;
}

.vignetteList {
    display: flex;
    margin-top: 18px;
}

.domainsTitle {
    font-weight: bold;
    margin-bottom: 12px;
    margin-left: 4px;
    font-size: 24px;
}

.iconStatus {
    margin-right: 4px;
}

.settingsWrapper {
    display: flex;
    align-items: center;
    // border: 1px solid #312a3c;
    border-radius: 10px;
    margin-top: 12px;
    // padding: 3px 8px;
    // margin-right: 12px;
    flex-wrap: wrap;
}

.settingsContainer {
    display: flex;
    justify-content: flex-end;
}

.nextRoundWrapper {
    display: flex;
}

.nextRoundTitle {
    font-weight: bold;
    font-size: 24px;
}

.nextRoundContent {}

.nextRoundPicture {
    margin-right: 12px;
    margin-top: 2px;
}

.alertNextRound {
    margin-bottom: 44px;
}

.toSendUrl {
    color: white !important;
}

.ycLogoDomainName {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 6px;
    margin-right: 6px;
}

.welcomePanelWrapper {
    filter: blur(16px);
}

.step {
    margin-bottom: 24px;
    margin-top: 12px;

    .stepTitle {
        font-weight: bold;
    }

    .stepDescription {
        color: #bbbbbb;
    }

    .inviteLink {
        margin-top: 4px
    }
}


@media screen and (max-width: 520px) {
    .vignetteList {
        flex-wrap: wrap;
    }

    .nextRoundWrapper {
        flex-wrap: wrap;
    }

    .rejectedWrapper {
        .rejectedInner {
            flex-wrap: wrap;
            align-items: flex-start;

            .rejectedCta {
                margin-left: 54px;
            }
        }
    }
}
.wrapper {
    margin: 12px;

    .inner {
        width: 100%;
        max-width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.vignetteWrapper {
    width: 480px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #302b35;
    border-radius: 10px;
    margin-bottom: 44px;

    .vignetteInner {
        padding: 48px 64px;
    }
}

.logo {
    border-radius: 10px;
}

.actions {
    text-align: center;
}

.registerWrapper {
    display: flex;
    background: #302b35;
    border-radius: 10px;
    max-width: 100%;
}

.registerLeft {
    width: 350px;
    max-width: 100%;
    padding: 48px 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rate {
    display: flex;
    align-items: center;
}

.rateText {
    margin-left: 6px;
}

.argumentWrapper {
    display: flex;
    margin-bottom: 32px;
}

.argumentIcon {
    color: #08a87f;
    margin-right: 12px;
    font-size: 20px;
    margin-top: 2px;
}

.argumentList {
    margin-top: 32px;
}

@media screen and (max-width: 800px) {
    .registerWrapper {
        flex-wrap: wrap-reverse;
        width: 500px;
        max-width: 100%;
        justify-content: center;

        margin-top: 24px;
    }

    .registerLeft {
        padding: 46px 40px;
    }
}

@media screen and (max-width: 500px) {
    .vignetteWrapper {
        .vignetteInner {
            padding: 46px 40px;
        }
    }
}
.wrapper {
    max-width: 100%;
    margin: auto;
    margin-top: 64px;

    .inner {
        margin: 12px;
    }

}

.label {
    font-weight: bold;
    color: #08a87f;
    margin-bottom: 6px;
}

.accountInfoElement {
    width: 300px;
}

.accountInfo {
    margin: 4px;
    margin-top: 24px;
}

.domainList {
    width: 500px;
    max-width: 100%;

    .domainWrapper {
        margin-bottom: 12px;
        display: flex;

        align-items: center;

        .domainName {
            flex-grow: 1;
        }
    }
}
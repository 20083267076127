.wrapper {
    margin: 12px;

    .inner {
        width: 1060px;
        max-width: calc(100% - 26px);
        height: 54px;
        margin: auto;
        background: #2c2533;
        padding-left: 12px;
        padding-right: 16px;
        margin-top: 16px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: 1px solid #3a3043;

        .left {
            flex-grow: 1;
        }
    }
}

.name {
    color: #ebebeb;
}

@media screen and (max-width: 420px) {
    .name {
        display: none;
    }
}